<template>
    <div id="onlinePie" style="width: 100px;height: 100px;"></div>
</template>

<script>
    import * as echarts from 'echarts';
    export default {
        data(){
            return {
                chart:null
            }
        },
        mounted() {
            this.chart = echarts.init(document.getElementById('onlinePie'))
        },
        methods:{
            updateOnlinePie(online, offline) {
                this.chart.setOption({
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        show: false
                    },
                    series: [
                        {
                            name: '',
                            type: 'pie',
                            radius: ['40%', '80%'],
                            avoidLabelOverlap: false,
                            label: {
                                show: false,
                                position: 'center'
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: '14',
                                    // fontWeight: 'bold'
                                }
                            },
                            labelLine: {
                                show: true
                            },
                            data: [
                                {
                                    value: online, name: '在线设备', itemStyle: {
                                        color: "#91cc75"
                                    }
                                },
                                {
                                    value: offline, name: '离线设备', itemStyle: {
                                        color: "#CCCCCC"
                                    }
                                },

                            ]
                        }
                    ]
                });
            }
        }
    }
</script>

<style scoped>

</style>
